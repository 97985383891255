const MAX_SPEED = 4;
const PARTICLE_STROKE_WIDTH = 1;


export class Color {
  constructor(r, g, b, a) {
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
  }
}


export class Particle {
  
  constructor(p5Handle, color) {
    this.p5Handle = p5Handle;
    this.position = this.p5Handle.createVector(this.p5Handle.random(this.p5Handle.width), this.p5Handle.random(this.p5Handle.height));
    this.velocity = this.p5Handle.createVector(0, 0);
    this.acceleration = this.p5Handle.createVector(0, 0);
    this.previousPosition = this.position.copy();
    this.color = color;
  }

  update() {
    this.velocity.add(this.acceleration);
    this.position.add(this.velocity);
    this.acceleration.mult(0);
    this.velocity.limit(MAX_SPEED);
  }

  follow(flowfield, scale, cols, mouseX, mouseY) {
    let x = this.p5Handle.floor(this.position.x / scale);
    let y = this.p5Handle.floor(this.position.y / scale);
    let index = x + y * cols;
    let force = flowfield[index];
    this.applyForce(force);
  }

  applyForce(force) {
    this.acceleration.add(force);
  }


  render() {
    /*   this.p5Handle.stroke(0, 10); */
    this.p5Handle.stroke(
      this.color.r,
      this.color.g,
      this.color.b,
      this.color.a
    );

    this.p5Handle.strokeWeight(PARTICLE_STROKE_WIDTH);
    this.p5Handle.line(
      this.position.x,
      this.position.y,
      this.previousPosition.x,
      this.previousPosition.y,
    );
    this.updatePreviousPosition();
  }

  updatePreviousPosition() {
    this.previousPosition.x = this.position.x;
    this.previousPosition.y = this.position.y;
  }

  constrainToEdges() {
    if (this.position.x > this.p5Handle.width) {
      this.position.x = 0;
      this.updatePreviousPosition();
    }
    if (this.position.x < 0) {
      this.position.x = this.p5Handle.width;
      this.updatePreviousPosition();
    }
    if (this.position.y > this.p5Handle.height) {
      this.position.y = 0;
      this.updatePreviousPosition();
    }
    if (this.position.y < 0) {
      this.position.y = this.p5Handle.height;
      this.updatePreviousPosition();
    }
  }

}