import React, {useEffect, useRef} from 'react';
import p5, { Vector } from 'p5';
import { Particle, Color } from './particle';

export function FlowField(props) {

  const sketch = useRef(null);

  let s = (p5) => {
    const scale = 10;
    const step = 0.1;
    let height = sketch.current?.parentElement?.offsetHeight;
    let width = sketch.current?.parentElement?.offsetWidth;
    let cols;
    let rows;
    let zOffset = 0;
    let iterationCount = 0;
    let particles = [];
    let flowfield;

    p5.setup = () => {
      if (height && width) {
        p5.createCanvas(width, height)
      };

      cols = p5.floor(p5.width / scale);
      rows = p5.floor(p5.height / scale);
      flowfield = new Array(cols * rows);

      for (let i = 0; i < props.numParticles; i++) {
        let randomAlpha = p5.noise(props.particleA, props.particleA, props.particleA) * p5.TWO_PI * 2
        particles[i] = new Particle(
          p5,
          new Color(
            props.particleR,
            props.particleG,
            props.particleB,
            // props.particleA,
            randomAlpha,
          )
        );
      }

      p5.background(
        props.bgR,
        props.bgG,
        props.bgB,
        props.bgA,
      );
    };

    p5.draw = () => {
      let yOffset = 0;
      for (let y = 0; y < rows; y++) {
        let xOffset = 0;
        for (let x = 0; x < cols; x++) {
          let index = x + y * cols;
          let angle = p5.noise(xOffset, yOffset, zOffset) * p5.TWO_PI * 2;
          let v = Vector.fromAngle(angle);
          v.setMag(0.5);

          flowfield[index] = v;
          xOffset += step;
        }
        yOffset += step;
      }

      zOffset += 0.0005;
      let mouseX = p5.mouseX;
      let mouseY = p5.mousey;
      if (iterationCount < props.animationLength) {
        for (let i = 0; i < particles.length - 1; i++) {
          particles[i].follow(flowfield, scale, cols, mouseX, mouseY);
          particles[i].update();
          particles[i].constrainToEdges();
          particles[i].render();
        }
      }

      iterationCount += 1;

    };

    p5.windowResized = () => {
      p5.resizeCanvas(sketch.current?.parentElement?.offsetWidth, sketch.current?.parentElement?.offsetHeight);
      p5.background(
        props.bgR,
        props.bgG,
        props.bgB,
        props.bgA,
      );
    }

  };

  let ignore = false; 
  useEffect(() => {
    if (sketch.current && !ignore) new p5(s, sketch.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => { ignore = true; };
  }, [sketch.current]);

  return <div ref={sketch} />;
}