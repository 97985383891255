import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage } from './components/pages/Home';
import { AboutPage } from './components/pages/About';
import { CVPage } from './components/pages/CV';
import { ExamplesPage } from './components/pages/Examples';
import { ContactPage } from './components/pages/Contact';


function App() {
  return (
    <div className="App" style={{height: "100vh"}}>
      <Router>
        <Routes>
          <Route exact path='/' element={<HomePage/>}/>
          <Route exact path='/about' element={<AboutPage/>}/>
          <Route exact path='/examples' element={<ExamplesPage/>}/>
          <Route exact path='/cv' element={<CVPage/>}/>
          <Route exact path='/contact' element={<ContactPage/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;