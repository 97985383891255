import "../../styles/content.css";
import { BasePage } from './Base';

export function AboutPage() {
  return (
    <BasePage pageHeader="About Me" pageSubheader="Hi! My name is Rich, I'm a senior software engineer based in the Midlands, UK.">
      <div className="summary-container">
        <p className='summary-content-small'>In 2014 I graduated from the University of York with a degree in Astrophysics, since then I have worked as a software engineer with a focus on backend development.</p>
        <p className='summary-content-small'>Throughout my career so far, I have enjoyed working in a number of different spaces from digital marketing to fraud detection (my favourite) and I am passionate about using tech for good.</p>
        <p className='summary-content-small'>I have mainly focused on Python development using the Django Framework, Flask and FastAPI, but I also enjoy building frontends for my projects with React using javascript.</p>
        <p className='summary-content-small'>In my spare time you can find me at the gym, in the kitchen or working on my latest side-project.</p>
      </div>
    </BasePage>
  );
}