import React, {useState} from "react";
import { PageHeader } from "../headers";
import { TopNavigation } from "../nav";


export function BasePage(props) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <div style={{height: "100vh", backgroundColor: "rgba(29, 33, 41, 255)"}}>
      <TopNavigation expanded={isNavExpanded} onExpandedClick={() => {setIsNavExpanded(!isNavExpanded)}}/>
      {!isNavExpanded && <PageHeader header={props.pageHeader} subheader={props.pageSubheader}/>}
      {!isNavExpanded && props.children}
    </div>
  );
}