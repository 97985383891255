import { FlowField } from '../field/field';
import { BasePage } from './Base';

export function HomePage() {
  return (
    <BasePage
      pageHeader="Richard Smith"
      pageSubheader="Software Engineer"
    >
      <FlowField
        numParticles={2000}
        animationLength={4000}
        particleR={94}
        particleG={223}
        particleB={214}
        particleA={100}
        bgR={29}
        bgG={33}
        bgB={41}
        bgA={255}
      />
    </BasePage>
  );
}